import React, { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import endOfDay from 'date-fns/endOfDay';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import i18next from 'i18next';
import { TFunction } from 'react-i18next';
import { Link as LinkMUI } from '@mui/material';
import { toast } from 'react-toastify';

import { Icon, FilterType } from '@ibox/ui';

import { formatNumberRU } from '../../../../utils/numberFormatterRU';
import { DataTooltip } from '../../../../fragments/Data/data-tooltip';
import { cutStringUsingEllipsis } from '../../../../utils';
import {
  documentTypesIcons,
  documentTypesValues,
  initialDocumentTypesValues,
} from '../../../../constants/documents';
import { translate } from '../../../Translate/Translate';
import { Value } from '../../../../fragments/Table/Cell/value';
import { makeCell } from '../../../../common_components/CellHelpers/MakeCell';
import {
  START_YEAR,
  YEARS_AFTER,
} from '../../../../constants/date-picker-years';
import { getFloatRublesFromCoins } from '../../../../utils/getFloatRublesFromCoin';

import { documentTypesFilterOptionsList } from './filters/DocumentType';

import css from './index.module.scss';

export const columnIds = {
  DOC_DATE: 'docDate',
  DOCUMENT_TYPE: 'documentType',
  NUMBER: 'number',
  SEARCH: 'search',
  DOCUMENT_STATUS: 'documentStatus',
  TOTAL: 'total',
  SENDER_INN: 'senderInn',
  RECEIVER_INN: 'receiverInn',
};

const handlerBodyClick = (e: React.MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
};

const onCopy = (id: string) => {
  toast.success(`${id} ${i18next.t('успешно скопирован')}`);
};

export const documentStatuses = {
  CHECKED_NOT_OK: 'CHECKED_NOT_OK',
  CHECKED_OK: 'CHECKED_OK',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const documentStatusesNames = {
  [documentStatuses.CHECKED_NOT_OK]: translate('Обработан с ошибками'),
  [documentStatuses.CHECKED_OK]: translate('Обработан успешно'),
  [documentStatuses.IN_PROGRESS]: translate('Проверяется'),
};

export const documentStatusesInitialNames = {
  [documentStatuses.CHECKED_NOT_OK]: translate('Обработан с ошибками'),
  [documentStatuses.CHECKED_OK]: translate('Обработан успешно'),
  [documentStatuses.IN_PROGRESS]: translate('Проверяется'),
};

export const documentStatusesColorsMap = {
  [documentStatuses.CHECKED_NOT_OK]: '#f34747',
  [documentStatuses.CHECKED_OK]: '#48c834',
  [documentStatuses.IN_PROGRESS]: '#f5a020',
};

export const documentStatusesIconsMap = {
  [documentStatuses.CHECKED_NOT_OK]: <Icon name="DocumentInvalid" />,
  [documentStatuses.CHECKED_OK]: <Icon name="DocumentPublished" />,
  [documentStatuses.IN_PROGRESS]: <Icon name="DocumentProcessing" />,
};

export const filtersHistoryName = 'participantsListFiltersHistory';
export const savedFiltersName = 'participantsListSavedFilters';
const minDate = new Date('07-01-2021');
export const getFilters = (t: TFunction<'translation'>) => [
  {
    id: columnIds.DOC_DATE,
    label: t('Дата'),
    type: FilterType.DATE_RANGE,
    config: {
      startLabel: t('От'),
      endLabel: t('До'),
      keyFrom: 'dateFrom',
      keyTo: 'dateTo',
      minDate,
      minDateTo: minDate,
      maxDate: endOfDay(new Date((START_YEAR + YEARS_AFTER).toString())),
    },
  },
  {
    id: columnIds.SEARCH,
    label: t('Номер документа'),
    type: FilterType.TEXT,
  },
  {
    id: columnIds.DOCUMENT_TYPE,
    label: t('Тип документа'),
    type: FilterType.SELECT,
    config: {
      options: documentTypesFilterOptionsList.map((type) => ({
        value: `${type}`,
        label: t(initialDocumentTypesValues[type]),
      })),
    },
  },
  {
    id: columnIds.DOCUMENT_STATUS,
    label: t('Статус документа'),
    type: FilterType.SELECT,
    config: {
      options: Object.values(documentStatuses).map((type) => ({
        value: `${type}`,
        label: documentStatusesInitialNames[type],
      })),
    },
  },
];

export const getFiltersTabs = (t: TFunction<'translation'>) => [
  {
    label: t('Все фильтры'),
    value: 0,
  },
  {
    label: t('Избранные фильтры'),
    value: 1,
  },
  {
    label: t('История фильтров'),
    value: 2,
  },
];

export const getNewColumns = (t: TFunction<'translation'>) => {
  return [
    {
      header: t('Дата'),
      id: columnIds.DOC_DATE,
      accessorKey: 'docDate',
      Cell: ({ cell }) => {
        return (
          <Value>
            {cell.getValue() &&
              `${moment(cell.getValue()).format('DD.MM.YYYY')}`}
          </Value>
        );
      },
      size: 120,
    },
    {
      header: t('Тип документа'),
      id: columnIds.DOCUMENT_TYPE,
      accessorKey: 'type',
      Cell: makeCell({
        icons: documentTypesIcons,
        values: documentTypesValues,
      }),
      size: 300,
    },
    {
      header: t('Номер документа'),
      id: columnIds.SEARCH,
      accessorKey: 'id',
      Cell: ({ row, cell }) => {
        const cellId = cell.getValue() || '';
        const renderValues = () => {
          return (
            <Value className={css.wrapper} isSelected={row.isSelected}>
              <LinkMUI
                className={css.link}
                href={`${
                  window.env.REACT_APP_PG_URL
                }documents/list/${encodeURIComponent(cellId)}/basic-info`}
              >
                {cutStringUsingEllipsis(cellId, 36)}
              </LinkMUI>
            </Value>
          );
        };
        const title = useCallback(
          () => (
            <div className={css.wrapperTitle} onClick={handlerBodyClick}>
              <div className={css.text}>{cellId}</div>
              <CopyToClipboard text={cellId} onCopy={() => onCopy(cellId)}>
                <Icon className={css.iconDownload} name="Download" />
              </CopyToClipboard>
              <LinkMUI
                className={css.linkMail}
                href={`mailto:support@crpt.ru?subject=Обращение по документу ${cellId}&body=${cellId}`}
              >
                <Icon className={css.iconMessage} name="Message" />
              </LinkMUI>
            </div>
          ),
          [cellId]
        );

        return (
          <div className={css.docTypeWrap}>
            <DataTooltip
              alwaysDisplay
              interactive
              arrow={false}
              placement="bottom-start"
              render={renderValues}
              setMaxWidth="none"
              title={title()}
            />
          </div>
        );
      },
      size: 270,
    },
    {
      header: t('Статус документа'),
      id: columnIds.DOCUMENT_STATUS,
      accessorKey: 'status',
      Cell: makeCell({
        colors: documentStatusesColorsMap,
        icons: documentStatusesIconsMap,
        values: documentStatusesNames,
      }),
      minWidth: 250,
    },
    {
      header: t('Сумма, драм'),
      id: columnIds.TOTAL,
      accessorKey: 'total',
      Cell: ({ cell }) => {
        return (
          <Value>
            {cell.value
              ? formatNumberRU(getFloatRublesFromCoins(cell.value))
              : '—'}
          </Value>
        );
      },
      size: 200,
    },
    {
      header: t('Отправитель'),
      id: columnIds.SENDER_INN,
      accessorKey: 'senderName',
      size: 250,
    },
    {
      header: t('Получатель'),
      id: columnIds.RECEIVER_INN,
      accessorKey: 'receiverName',
      size: 250,
    },
  ];
};
