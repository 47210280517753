import React from 'react';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Input } from '../../../../common_components/Form/Input';
import { translate } from '../../../Translate/Translate';

type ParticipantsInfoProps = {
  disabled?: boolean;
};

export const ParticipantsInfo: React.FC<ParticipantsInfoProps> = ({
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">{translate('Участие в системе')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Input
          fullWidth
          defaultValue={disabled ? '—' : ''}
          disabled={disabled}
          label={translate('ID участника')}
          name="id"
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          fullWidth
          defaultValue={disabled ? '—' : ''}
          disabled={disabled}
          label={translate('omsId')}
          name="omsId"
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          fullWidth
          defaultValue={disabled ? '—' : ''}
          disabled={disabled}
          format={(v: string) => t(v)}
          label={translate('Статус участника')}
          name="statusName"
        />
      </Grid>
      <Grid item md={6} xs={false} />
      <Grid item xs={6}>
        <Input
          fullWidth
          defaultValue={disabled ? '—' : ''}
          disabled={disabled}
          label={translate('Дата регистрации')}
          name="registrationDate"
        />
      </Grid>
    </Grid>
  );
};

ParticipantsInfo.defaultProps = {
  disabled: true,
};
