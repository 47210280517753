import React from 'react';

import { useSelector } from 'react-redux';

import { getLocalizedTheme, Theme as IBOXTheme } from '@ibox/ui';

import { CountryCode, setFavicon } from '../../helpers';
import { getLanguage } from '../App/App.selectors';

type ThemeProps = {
  children: string | JSX.Element | JSX.Element[];
};

export const Theme = ({ children }: ThemeProps) => {
  const countryCode = window.env.COUNTRY_CODE || 'AM';

  React.useEffect(() => {
    setFavicon(countryCode as CountryCode);
    const body = document.getElementById('body');
    if (body) {
      body.className = `theme-${countryCode.toLowerCase()}`;
    }
  }, [countryCode]);

  const language = useSelector(getLanguage);

  const theme = React.useMemo(
    () => getLocalizedTheme(countryCode, language),
    [countryCode, language]
  );

  return <IBOXTheme theme={theme}>{children}</IBOXTheme>;
};
